import { NextPage } from 'next';
import { SignInLayout } from '~/components/layouts';
import { SignInTemplate } from '~/components/templates/signInTemplate';

const SignIn: NextPage = () => {
    return (
        <SignInLayout>
            <SignInTemplate />
        </SignInLayout>
    );
};

export default SignIn;
